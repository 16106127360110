import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
//import fileDownload from "js-file-download";
import { userContext } from "../Context/userContext";
import "../css/gridgallery.css";
import Loading from "./Loading";
///import { Modal, Spinner } from "bootstrap";

const Images = (props) => {
  const { DateOk } = useContext(userContext);
  const [idList, setIdList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingHeading, setloadingHeading] = useState("Loading...");

  useEffect(() => {
    setLoading(true);
    setloadingHeading("Loading...");
    if (DateOk) {
      axios
        .get(`https://lynxlife.club/api/lv1/content/list/${props.contentType}/0/0`)
        .then((res) => {
          console.log("Response received:", res); // Log the response to inspect it
          if (res.items && res.items.id) {
            setIdList(res.items.id);
          } else {
            console.error("Unexpected response structure:", res); // Log unexpected structure
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error); // Log any errors
          setLoading(false);
        });
    }
  }, [props.contentType, DateOk]); 
  //useEffect(() => {
  //  if (imgurl !== "") {
  //   const link = document.createElement("a");
  //   link.href = imgurl;
  //  link.download = imgurl;
  //  link.click();
  // setImgUrl("");
  // }
  //}, [imgurl]);

  // const imgs = [
  // "http://www.logicsms.co.za/T/imgcontent/1030914.jpg",
  // "http://www.logicsms.co.za/T/imgcontent/1769394.jpg",
  //  "http://www.logicsms.co.za/T/imgcontent/2090644.jpg",
  //  "http://www.logicsms.co.za/T/imgcontent/2438212.jpg",
  //  "http://www.logicsms.co.za/T/imgcontent/2879812.jpg",
  // "http://www.logicsms.co.za/T/imgcontent/2975688.jpg",
  // "http://www.logicsms.co.za/T/imgcontent/4399983.jpg",
  //  "http://www.logicsms.co.za/T/imgcontent/5957562.jpg",
  // "http://www.logicsms.co.za/T/imgcontent/6022130.jpg",
  // "http://www.logicsms.co.za/T/imgcontent/6087130.jpg",
  //  "http://www.logicsms.co.za/T/imgcontent/6151783.jpg",
  //  "http://www.logicsms.co.za/T/imgcontent/6704324.jpg",
  // "http://www.logicsms.co.za/T/imgcontent/6868313.jpg",
  //];

  const dwnldImg = (imgSrc) => {
    setLoading(true);
    setloadingHeading("Downloading...");
    axios({
      url: imgSrc,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Lynximage.jpg");
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      })
      .catch({});
  };
  const handleClick = (e) => {
    e.preventDefault();

    const link = document.createElement("a");
    link.href = e.src;
    link.download = e.src;
    link.click();
  };
  return (
    <div className="container">
      {" "}
      <div className="gallery-container">
        <h3 className="text-white">
          {loading ? <Loading heading={loadingHeading} /> : props.contentType}
          🔥
        </h3>
        <div className="gallery-grid">
          {idList.map((id) => (
            <img
              onClick={() => {
                dwnldImg(
                  `https://lynxlife.club/api/lv1/content/${props.contentType}/${id}`
                );
              }}
              key={id}
              src={`https://lynxlife.club/api/lv1/content/${props.contentType}/${id}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
export default Images;
//{oImg.map((img) => renderImageContent(img))}
