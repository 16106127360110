import React, { Component, useContext, useState } from "react";
import { userContext } from "../Context/userContext";

const Loading = (props) => {
  return (
    <div className="container">
      <h4>
        {props.heading}{" "}
        <div className="spinner-border text-dark " role="status">
          <span className="sr-only"></span>
        </div>
      </h4>
    </div>
  );
};

export default Loading;
