import React, { useContext, useState } from "react";
import { userContext } from "../Context/userContext";
import Images from "./componentImages";
import Loading from "./Loading";

import Audio from "./componentAudio";
import SideBar from "./SideBar";

import axios from "axios";
import "../css/LynxStyle.css";
import MiniCardAboutGPT from "./MiniCardAboutGPT";
//import { getSpaceUntilMaxLength } from "@testing-library/user-event/dist/utils";

const Lifestyle = () => {
  const { contentType, ShowMenu, Msisdn } = useContext(userContext);
  const [loading, setLoading] = useState(false);
  const [loadingHeading, setloadingHeading] = useState("Asking the experts...");
  const [Question, setQuestion] = useState("");
  const [Answer, setAnswer] = useState("Ask me ANYTHING!");
  const AskLynx = (e) => {
    setLoading(true);
    // Somewhere in your code, e.g. inside a handler:
    axios
      .get(
        "https://lynxlife.club/API/smsgpt/"+Msisdn+"/basic?query" + Question
      )
      .then(function (res) {
        console.log(JSON.stringify(res));
        setAnswer(res.data);
        setLoading(false);
      });
  };
  return (
    <div className="container">
      <br />
      <br />
      <h3 className="text-white">
        Lifestyle Assistant{" "}
        <small className="smsgpt">Powered by CHATGPT AI!</small>
      </h3>
      <div className="form-group">
        <label htmlFor="exampleFormControlTextarea1" className="text-white">
          Question
        </label>
        <textarea
          className="form-control rounded-0 "
          rows="5"
          onChange={(e) => setQuestion(e.target.value)}
        ></textarea>
        <button
          className="btn btn-dark smsgpt btn-lg btn-block"
          type="button"
          onClick={() => {
            AskLynx();
          }}
        >
          Submit
        </button>
      </div>
      <div className="smsgpt">
        {loading ? <Loading heading={loadingHeading} /> : Answer}
      </div>
      <MiniCardAboutGPT />
    </div>
  );
};
//{(contentType === "i" && <Images contentType={contentType} />) ||
// (contentType === "z" && <Images />) ||
// (contentType === "w" && <Images />) ||
// (contentType === "s" && <Audio />)}

//  {(contentType==="Audio" ? <Audio /> :  <Images Type={contentType} />)}
export default Lifestyle;
