import React from "react";
import { userContext } from "../Context/userContext";
import "../css/LynxStyle.css";
import { Link } from "react-router-dom";
import { useContext } from "react";
const Welcome = () => {
  const { dateOk } = useContext(userContext);
  return (
    <div className="container-fluid  viewport">
      <section className="text-center text-lg-start ">
        <div className="container">
          <div className="row g-0 align-items-center">
            <div className="col-lg-6 mb-5 mb-lg-0">
              <div className=" lynx_darkcards_noborder cascading-right img_bootstrap">
                <div className=" p-5  text-center">
                  <h5 className="fw-normal mb-3 pb-3">
                    Welcome to the Lynx LifeStyle Club!
                  </h5>

                  <div className="form-outline mb-4">
                    <p className="mb-5 pb-lg-2">
                      Awesome <strong>UNLIMITED</strong> content and a chance to{" "}
                      <strong>WIN DAILY AIRTIME</strong> and other prizes
                    </p>
                    <p className="mb-5 pb-lg-2">
                      Insane <strong>DISCOUNTS</strong> discounts from our many
                      partners!
                    </p>
                  </div>

                  <div className="form-outline mb-4"></div>

                  {dateOk ? (
                    <div className="pt-1 mb-4">
                      <Link
                        className="btn btn-dark btn-lg btn-block"
                        type="button"
                        to="/main"
                      >
                        Take me to the Action!
                      </Link>
                    </div>
                  ) : (
                    <div className="pt-1 mb-4">
                      <Link
                        className="btn btn-dark btn-lg btn-block"
                        type="button"
                        to="/login"
                      >
                        Click here to login!
                      </Link>
                    </div>
                  )}

                  <p className="lynx_opacity_text">
                    Don't have an account? SMS lynx to 33270 (std sms rates, R2
                    per day, T&Cs apply)
                  </p>
                  <Link className="lynx_opacity_text btn nav-link" to="/Terms">
                    Terms
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-6 mb-5 mb-lg-0 container-fluid lynx_m_img">
              <img
                src="lynx2.jpg"
                className="rounded-4 lynx_m_img shadow-4 "
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Welcome;
