import React, { useState } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/IQ.css"; // Assume you have your custom CSS file for additional styling
//import IQImage from './iq-test-image.jpg'; // Import your IQ test image

function QuizPage() {
  // Define state to manage the quiz questions and answers
  const [questions, setQuestions] = useState([
    {
      question: "What is the capital city of South Africa?",
      options: ["Cape Town", "Johannesburg", "Pretoria", "Durban"],
      answer: "Pretoria",
    },
    {
      question: "Who was the first black president of South Africa?",
      options: [
        "Nelson Mandela",
        "Thabo Mbeki",
        "Jacob Zuma",
        "Cyril Ramaphosa",
      ],
      answer: "Nelson Mandela",
    },
    {
      question:
        "Which iconic South African mountain is one of the New Seven Wonders of Nature?",
      options: [
        "Table Mountain",
        "Drakensberg Mountains",
        "Kilimanjaro",
        "Mount Kenya",
      ],
      answer: "Table Mountain",
    },
    {
      question: "What is the largest national park in South Africa?",
      options: [
        "Kruger National Park",
        "Addo Elephant National Park",
        "Tsitsikamma National Park",
        "Kgalagadi Transfrontier Park",
      ],
      answer: "Kruger National Park",
    },
    {
      question: "Which sport is the most popular in South Africa?",
      options: ["Soccer", "Cricket", "Rugby", "Golf"],
      answer: "Soccer",
    },
    {
      question: "Which South African city is known as the 'Mother City'?",
      options: ["Cape Town", "Johannesburg", "Durban", "Pretoria"],
      answer: "Cape Town",
    },
    // Add more questions here
  ]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [scorePercentage, setScorePercentage] = useState(0);
  // Function to handle selecting an answer
  const handleAnswerSelect = (selectedAnswer) => {
    if (selectedAnswer === questions[currentQuestionIndex].answer) {
      setScore(score + 1);
    }
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      // End of quiz, display score or any other message
      calculateScorePercentage();
      setShowModal(true);
      // alert(`Quiz completed! Your score is ${score}/${questions.length}`);
      // You can add more logic here such as displaying the score or providing a button to restart the quiz
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const calculateScorePercentage = () => {
    const percentage = (score / questions.length) * 100;
    setScorePercentage(percentage);
  };
  return (
    <Container fluid className="quiz-page">
      <Row>
        <Col xs={12} className="text-center">
          <img
            src="Quizthink.jpeg"
            alt="South Africa"
            className="img-fluid mt-5"
          />

          <h1 className="mt-3 fancy-font">General Knowledge Quiz</h1>
          <p className="lead fancy-text">
            Test your knowledge about South Africa!
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="text-center">
          <h3 className="mt-4">{questions[currentQuestionIndex].question}</h3>
          <div className="options">
            {questions[currentQuestionIndex].options.map((option, index) => (
              <Button
                key={index}
                variant="outline-primary"
                className="option-button mt-2"
                onClick={() => handleAnswerSelect(option)}
              >
                {option}
              </Button>
            ))}
          </div>
        </Col>
      </Row>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Congratulations!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Your score is {score}/{questions.length}. Well done!
          </p>
          {scorePercentage <= 50 && (
            <p>
              Better luck next time! Keep practicing to improve your knowledge.
            </p>
          )}
          {scorePercentage > 50 && scorePercentage <= 80 && (
            <p>
              Congratulations! You're on the right track. Keep up the good work!
            </p>
          )}
          {scorePercentage > 80 && scorePercentage < 100 && (
            <p>
              Excellent job! You have a strong knowledge base. Keep learning!
            </p>
          )}
          {scorePercentage === 100 && (
            <p>Perfect score! You're a true South Africa expert!</p>
          )}
          <p>
            Want to stay clever or learn more? Subscribe to get daily facts
            straight to your phone for R2/day.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" href="#" onClick={handleCloseModal}>
            Subscribe
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default QuizPage;
