import React from "react";
import { useState } from "react";

import { Container, Row, Col, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/IQ.css"; // Assume you have your custom CSS file for additional styling
//import IQImage from './iq-test-image.jpg'; // Import your IQ test image
import QuizPage from "./QuizPage"; // Import the QuizPage component

function IQquiz() {
  const [quizStarted, setQuizStarted] = useState(false);

  const handleStartQuiz = () => {
    setQuizStarted(true);
  };

  return (
    <Container fluid className="home-page">
      {!quizStarted ? (
        <Row>
          <Col xs={12} className="text-center">
            <img src="QUIZ.jpeg" alt="IQ Test" className="img-fluid mt-5" />
            <h1 className="mt-3 fancy-font">🧠 Discover Your IQ! 🌟</h1>
            <p className="lead fancy-text">
              Take our free IQ test and challenge your mind today! 🚀
            </p>
            <Button
              variant="primary"
              size="lg"
              className="mt-4 fancy-button"
              onClick={handleStartQuiz}
            >
              Start Test Now
            </Button>
          </Col>
        </Row>
      ) : (
        <QuizPage />
      )}
    </Container>
  );
}

export default IQquiz;
