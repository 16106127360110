import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { userContext } from "../Context/userContext";

const BillingErr = () => {
  const { Msisdn } = useContext(userContext);
  return (
    <div className="container lynx_opacity_text">
      <h1>Welcome to Lynx LifeStyle!</h1>
      <p>
        Unfortunately your daily subscription has not been received recently.
        Please make sure you have airtime on your Cellphone number {Msisdn}
      </p>
      <br />
      <br />
      <p>
        Want <strong>UNLIMITED</strong> content and a chance to{" "}
        <strong>WIN DAILY AIRTIME</strong>?{" "}
      </p>
      Don't have an account? SMS lynx to 33270 (std sms rates, R2 per day, T&Cs
      apply)
    </div>
  );
};

export default BillingErr;
