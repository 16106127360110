import React, { useEffect, useRef, useState } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

function MyMapComponent({ center, zoom, marker }) {
  const ref = useRef();

  useEffect(() => {
    const googlemap = new window.google.maps.Map(ref.current, {
      center,
      zoom,
    });
    const createMarker = (markerObj) =>
      new window.google.maps.Marker({
        position: {
          lat: parseFloat(markerObj.lat),
          lng: parseFloat(markerObj.lng),
        },
        map: googlemap,
        title: markerObj.title,
      });
    marker.map((x) => {
      createMarker(x);
      //bounds.extend(marker.position);
    });
  }, [center, zoom, marker]);

  return (
    <div
      ref={ref}
      id="Gmap"
      className="vw-100 vh-100"
      // style={{
      //    height: "600px",
      // }}
    />
  );
}

function FamTrack() {
  const [center, setCenter] = useState({ lat: -28.4793, lng: 24.6727 });
  const [zoom, setZoom] = useState(4);
  const [markers, setMarkers] = useState([
    {
      lat: -28.4793,
      lng: 24.6727,
      title: "A",
    },
    {
      lat: -27.4793,
      lng: 23.6727,
      title: "B",
    },
    {
      lat: -29.4793,
      lng: 25.6727,
      title: "C",
    },
  ]);

  return (
    <div className="container">
      <br />
      <Wrapper apiKey="AIzaSyDg54YdUvYPG1MYQwltAF0W3hvz5QdcGIw">
        <MyMapComponent
          center={center}
          zoom={zoom}
          marker={markers}
          style={{
            height: "100%",
          }}
        />
      </Wrapper>
    </div>
  );
}

export default FamTrack;
