let paths = [
'M87.84 146.432c0 1.024-.362 1.792-1.088 2.304a4.02 4.02 0 01-2.368.768c-2.346 0-3.52-1.024-3.52-3.072v-45.44c0-2.048 1.174-3.072 3.52-3.072 2.347 0 3.52 1.024 3.52 3.072v9.6h13.504c2.176 0 4.438.81 6.784 2.432 2.304 1.622 3.456 4.182 3.456 7.68v19.072c0 3.542-1.045 5.974-3.136 7.296-2.09 1.366-4.48 2.048-7.168 2.048h-7.552c-1.536 0-2.346-1.002-2.432-3.008 0-2.005.81-3.008 2.432-3.008h6.4c1.579 0 2.71-.288 3.392-.864.683-.576 1.024-1.418 1.024-2.528V121.28c0-1.11-.341-2.176-1.024-3.2l.064.064c-.554-1.024-1.685-1.536-3.392-1.536H87.904l-.064 29.824z',
'M126.816 146.624c0 2.006-1.173 3.008-3.52 3.008-2.39 0-3.584-1.002-3.584-3.008v-45.888c0-2.005 1.195-3.008 3.584-3.008 2.347 0 3.52 1.003 3.52 3.008v45.888z',
'M145.312 149.12c-1.322 0-2.581-.181-3.776-.544a14.74 14.74 0 01-3.456-1.568v.064c-2.09-1.322-3.136-3.754-3.136-7.296v-3.136c0-1.75.31-3.274.928-4.576.619-1.301 1.483-2.336 2.592-3.104h-.064a12.458 12.458 0 013.392-1.632c1.195-.362 2.347-.544 3.456-.544h14.144v-5.952c0-1.066-.33-2.037-.992-2.912-.661-.874-1.781-1.312-3.36-1.312h-7.488c-1.621 0-2.741.395-3.36 1.184-.618.79-.928 1.718-.928 2.784v.512c0 2.219-1.194 3.328-3.584 3.328s-3.584-1.11-3.584-3.328v-.576c0-1.75.31-3.221.928-4.416a8.853 8.853 0 012.528-3.008h-.064a18.673 18.673 0 013.488-1.792c1.216-.47 2.379-.704 3.488-.704h9.728c1.067 0 2.208.235 3.424.704a16.903 16.903 0 013.424 1.792h-.064c1.11.81 1.963 1.867 2.56 3.168.598 1.302.896 2.806.896 4.512v25.664c0 2.048-1.173 3.072-3.52 3.072-.47 0-.906-.021-1.312-.064a2.611 2.611 0 01-1.088-.352 2.073 2.073 0 01-.768-.832c-.192-.362-.288-.842-.288-1.44l-.064-14.016h-12.928c-.938 0-1.664.107-2.176.32a2.598 2.598 0 00-1.216 1.024 23.491 23.491 0 00-.736 1.696 3.971 3.971 0 00-.224 1.376v2.496c0 1.11.33 1.952.992 2.528.662.576 1.803.864 3.424.864h7.168c1.579 0 2.368 1.003 2.368 3.008 0 .854-.192 1.568-.576 2.144-.384.576-.981.864-1.792.864h-8.384z',
'M205.472 138.944c0 3.499-1.152 6.08-3.456 7.744-2.261 1.622-4.522 2.432-6.784 2.432H184.48c-2.261 0-4.458-.81-6.592-2.432-1.024-.853-1.813-1.93-2.368-3.232-.554-1.301-.832-2.805-.832-4.512v-18.24c0-3.456 1.067-6.016 3.2-7.68a15.287 15.287 0 013.296-1.824c1.13-.448 2.23-.672 3.296-.672h10.752c1.024 0 2.155.203 3.392.608 1.238.406 2.39.95 3.456 1.632 1.024.726 1.846 1.728 2.464 3.008.619 1.28.928 2.795.928 4.544v3.2c0 2.048-1.173 3.072-3.52 3.072-2.346 0-3.52-1.024-3.52-3.072v-2.752c0-1.066-.33-2.037-.992-2.912-.661-.874-1.781-1.312-3.36-1.312h-8c-1.578 0-2.698.438-3.36 1.312-.661.875-.992 1.846-.992 2.912v18.112c0 1.024.33 1.984.992 2.88.662.896 1.782 1.344 3.36 1.344h8c1.579 0 2.699-.448 3.36-1.344.662-.896.992-1.856.992-2.88v-2.944c0-2.005 1.174-3.008 3.52-3.008 2.347 0 3.52 1.003 3.52 3.008v3.008z',
'M220.512 138.432v8.128c0 2.006-1.173 3.008-3.52 3.008-2.346 0-3.52-1.002-3.52-3.008v-45.568c0-2.048 1.174-3.072 3.52-3.072 2.347 0 3.52 1.024 3.52 3.072v27.136a189.274 189.274 0 013.552-4.128 405.824 405.824 0 003.904-4.48 1080.24 1080.24 0 013.872-4.48c1.28-1.472 2.475-2.848 3.584-4.128v.064c.768-.896 1.536-1.344 2.304-1.344.427 0 .843.096 1.248.288a8.32 8.32 0 011.312.8h-.064c1.024.854 1.536 1.707 1.536 2.56 0 .299-.064.63-.192.992a2.14 2.14 0 01-.64.928l-20.416 23.232zm21.376 6.4c.299.555.448 1.13.448 1.728 0 1.11-.64 1.942-1.92 2.496-.853.512-1.621.768-2.304.768-.47 0-.917-.16-1.344-.48a3.645 3.645 0 01-1.024-1.184l-6.4-12.352c-.384-.64-.576-1.28-.576-1.92 0-1.066.555-1.962 1.664-2.688.299-.213.608-.405.928-.576.32-.17.672-.298 1.056-.384.47-.085.918 0 1.344.256.427.256.79.598 1.088 1.024l7.04 13.312z',
'M104.736 226.432c0 2.048-1.173 3.072-3.52 3.072-2.346 0-3.52-1.024-3.52-3.072v-45.44c0-2.048 1.174-3.072 3.52-3.072 2.347 0 3.52 1.024 3.52 3.072v9.6h13.504c2.176 0 4.438.81 6.784 2.432 2.304 1.622 3.456 4.182 3.456 7.68v25.728c0 2.048-1.173 3.072-3.52 3.072-2.346 0-3.52-1.024-3.52-3.072V201.28c0-1.11-.341-2.176-1.024-3.2l.064.064c-.554-1.024-1.685-1.536-3.392-1.536h-12.352v29.824z',
'M157.408 190.592c1.11 0 2.25.235 3.424.704a19.487 19.487 0 013.424 1.792h-.064c1.11.81 1.963 1.867 2.56 3.168.598 1.302.896 2.806.896 4.512v18.176c0 3.499-1.152 6.08-3.456 7.744-2.261 1.622-4.522 2.432-6.784 2.432h-10.24c-1.11 0-2.24-.192-3.392-.576-1.152-.384-2.282-1.002-3.392-1.856-2.304-1.621-3.456-4.202-3.456-7.744v-18.176c0-1.706.299-3.21.896-4.512.598-1.301 1.45-2.357 2.56-3.168h-.064a17.279 17.279 0 013.392-1.792c1.195-.47 2.347-.704 3.456-.704h10.24zm-1.152 32.512c1.579 0 2.699-.448 3.36-1.344.662-.896.992-1.856.992-2.88v-18.048c0-1.066-.33-2.037-.992-2.912-.661-.874-1.781-1.312-3.36-1.312h-7.936c-1.578 0-2.698.438-3.36 1.312-.661.875-.992 1.846-.992 2.912v18.048c0 1.024.33 1.984.992 2.88.662.896 1.782 1.344 3.36 1.344h7.936z',
'M183.008 226.624c0 2.006-1.173 3.008-3.52 3.008-2.39 0-3.584-1.002-3.584-3.008v-45.888c0-2.005 1.195-3.008 3.584-3.008 2.347 0 3.52 1.003 3.52 3.008v45.888z',
'M198.88 218.88c0 1.024.33 1.984.992 2.88.662.896 1.782 1.344 3.36 1.344h8c1.579 0 2.699-.394 3.36-1.184a4.11 4.11 0 00.992-2.72v-.704c0-2.005 1.174-3.008 3.52-3.008 2.347 0 3.52 1.003 3.52 3.008v.768c0 1.792-.288 3.264-.864 4.416-.576 1.152-1.44 2.155-2.592 3.008-2.261 1.622-4.522 2.432-6.784 2.432h-10.752c-2.261 0-4.458-.81-6.592-2.432-1.024-.853-1.813-1.93-2.368-3.232-.554-1.301-.832-2.805-.832-4.512v-18.24c0-3.456 1.067-6.016 3.2-7.68a15.287 15.287 0 013.296-1.824c1.13-.448 2.23-.672 3.296-.672h10.752c1.024 0 2.155.203 3.392.608 1.238.406 2.39.95 3.456 1.632 1.024.726 1.846 1.728 2.464 3.008.619 1.28.928 2.795.928 4.544v8.64c0 .982-.288 1.856-.864 2.624-.576.768-1.632 1.152-3.168 1.152h-13.824c-1.536 0-2.346-1.002-2.432-3.008 0-2.005.81-3.008 2.432-3.008h10.88l-.064-5.952c0-1.066-.33-2.037-.992-2.912-.661-.874-1.781-1.312-3.36-1.312h-8c-1.578 0-2.698.438-3.36 1.312-.661.875-.992 1.846-.992 2.912v18.112z',
'M74.912 286.72c1.067 0 2.208.246 3.424.736a18.16 18.16 0 013.424 1.824h-.064a8.001 8.001 0 012.56 3.136c.598 1.28.896 2.816.896 4.608v1.92c0 3.499-1.152 6.08-3.456 7.744-1.11.854-2.24 1.472-3.392 1.856-1.152.384-2.282.576-3.392.576h-10.88c-1.11 0-2.24-.192-3.392-.576-1.152-.384-2.282-1.002-3.392-1.856-2.304-1.621-3.456-4.202-3.456-7.744v-.768c0-1.834 1.174-2.73 3.52-2.688 2.39 0 3.584.896 3.584 2.688v.704c0 1.024.32 1.984.96 2.88.64.896 1.77 1.344 3.392 1.344h8.448c1.622 0 2.752-.448 3.392-1.344.64-.896.96-1.856.96-2.88v-1.792c0-.554-.074-1.088-.224-1.6a3.728 3.728 0 00-.736-1.376c-.341-.405-.79-.736-1.344-.992s-1.237-.384-2.048-.384h-9.344c-2.176 0-4.458-.768-6.848-2.304-2.261-1.45-3.392-3.946-3.392-7.488v-2.176c0-1.706.299-3.21.896-4.512.598-1.301 1.45-2.357 2.56-3.168h-.064a17.279 17.279 0 013.392-1.792c1.195-.47 2.347-.704 3.456-.704h10.24c1.11 0 2.25.235 3.424.704a19.487 19.487 0 013.424 1.792h-.064c1.11.81 1.963 1.867 2.56 3.168.598 1.302.896 2.806.896 4.512v.576c0 .896-.298 1.547-.896 1.952-.597.406-1.472.63-2.624.672-2.346.043-3.52-.832-3.52-2.624v-.512c0-1.066-.33-2.037-.992-2.912-.661-.874-1.781-1.312-3.36-1.312h-7.936c-1.578 0-2.698.438-3.36 1.312-.661.875-.992 1.846-.992 2.912v1.536c0 1.11.33 2.112.992 3.008.662.896 1.782 1.344 3.36 1.344h9.408z',
'M103.776 309.12c-1.11 0-2.304-.128-3.584-.384a10.114 10.114 0 01-3.584-1.472c-1.194-.725-2.016-1.781-2.464-3.168-.448-1.386-.672-2.976-.672-4.768v-18.944c0-1.792.31-3.328.928-4.608.619-1.28 1.462-2.282 2.528-3.008a16.36 16.36 0 013.392-1.6c1.152-.384 2.283-.576 3.392-.576h10.304c1.11 0 2.23.192 3.36.576 1.13.384 2.294.918 3.488 1.6 1.024.726 1.846 1.739 2.464 3.04.619 1.302.928 2.827.928 4.576V315.2c0 2.048-1.173 3.072-3.52 3.072-2.346 0-3.52-1.002-3.52-3.008v-34.432c0-1.066-.33-2.037-.992-2.912-.661-.874-1.781-1.312-3.36-1.312h-8c-1.578 0-2.698.438-3.36 1.312-.661.875-.992 1.846-.992 2.912v18.048c0 1.024.33 1.984.992 2.88.662.896 1.782 1.344 3.36 1.344h6.656c1.536 0 2.304 1.003 2.304 3.008 0 .854-.181 1.568-.544 2.144-.362.576-.95.864-1.76.864h-7.744z',
'M163.168 298.944c0 1.707-.298 3.222-.896 4.544-.597 1.323-1.45 2.39-2.56 3.2h.064a15.956 15.956 0 01-3.392 1.76c-1.194.448-2.346.672-3.456.672h-10.304c-1.066 0-2.208-.224-3.424-.672a15.725 15.725 0 01-3.424-1.76h.064c-1.11-.81-1.962-1.877-2.56-3.2-.597-1.322-.896-2.837-.896-4.544V273.28c0-2.048 1.174-3.072 3.52-3.072 2.347 0 3.52 1.024 3.52 3.072v25.152c0 1.195.299 2.24.896 3.136.555 1.024 1.707 1.536 3.456 1.536h8c1.707 0 2.838-.512 3.392-1.536l-.064.064c.683-1.024 1.024-2.09 1.024-3.2V273.28c0-2.048 1.174-3.072 3.52-3.072 2.347 0 3.52 1.024 3.52 3.072v25.664z',
'M180.832 309.12c-1.322 0-2.581-.181-3.776-.544a14.74 14.74 0 01-3.456-1.568v.064c-2.09-1.322-3.136-3.754-3.136-7.296v-3.136c0-1.75.31-3.274.928-4.576.619-1.301 1.483-2.336 2.592-3.104h-.064a12.458 12.458 0 013.392-1.632c1.195-.362 2.347-.544 3.456-.544h14.144v-5.952c0-1.066-.33-2.037-.992-2.912-.661-.874-1.781-1.312-3.36-1.312h-7.488c-1.621 0-2.741.395-3.36 1.184-.618.79-.928 1.718-.928 2.784v.512c0 2.219-1.194 3.328-3.584 3.328s-3.584-1.11-3.584-3.328v-.576c0-1.75.31-3.221.928-4.416a8.853 8.853 0 012.528-3.008h-.064a18.673 18.673 0 013.488-1.792c1.216-.47 2.379-.704 3.488-.704h9.728c1.067 0 2.208.235 3.424.704a16.903 16.903 0 013.424 1.792h-.064c1.11.81 1.963 1.867 2.56 3.168.598 1.302.896 2.806.896 4.512v25.664c0 2.048-1.173 3.072-3.52 3.072-.47 0-.906-.021-1.312-.064a2.611 2.611 0 01-1.088-.352 2.073 2.073 0 01-.768-.832c-.192-.362-.288-.842-.288-1.44l-.064-14.016h-12.928c-.938 0-1.664.107-2.176.32a2.598 2.598 0 00-1.216 1.024 23.491 23.491 0 00-.736 1.696 3.971 3.971 0 00-.224 1.376v2.496c0 1.11.33 1.952.992 2.528.662.576 1.803.864 3.424.864h7.168c1.579 0 2.368 1.003 2.368 3.008 0 .854-.192 1.568-.576 2.144-.384.576-.981.864-1.792.864h-8.384z',
'M217.12 306.432c0 2.048-1.173 3.072-3.52 3.072-2.432 0-3.648-1.024-3.648-3.072v-26.496c0-3.498 1.046-5.93 3.136-7.296 2.09-1.365 4.502-2.048 7.232-2.048h7.296c1.579 0 2.368 1.003 2.368 3.008.086 2.006-.704 3.008-2.368 3.008h-6.784c-1.536 0-2.538.299-3.008.896-.47.598-.704 1.43-.704 2.496v26.432z',
'M242.464 298.88c0 1.024.33 1.984.992 2.88.662.896 1.782 1.344 3.36 1.344h8c1.579 0 2.699-.394 3.36-1.184a4.11 4.11 0 00.992-2.72v-.704c0-2.005 1.174-3.008 3.52-3.008 2.347 0 3.52 1.003 3.52 3.008v.768c0 1.792-.288 3.264-.864 4.416-.576 1.152-1.44 2.155-2.592 3.008-2.261 1.622-4.522 2.432-6.784 2.432h-10.752c-2.261 0-4.458-.81-6.592-2.432-1.024-.853-1.813-1.93-2.368-3.232-.554-1.301-.832-2.805-.832-4.512v-18.24c0-3.456 1.067-6.016 3.2-7.68a15.287 15.287 0 013.296-1.824c1.13-.448 2.23-.672 3.296-.672h10.752c1.024 0 2.155.203 3.392.608 1.238.406 2.39.95 3.456 1.632 1.024.726 1.846 1.728 2.464 3.008.619 1.28.928 2.795.928 4.544v8.64c0 .982-.288 1.856-.864 2.624-.576.768-1.632 1.152-3.168 1.152h-13.824c-1.536 0-2.346-1.002-2.432-3.008 0-2.005.81-3.008 2.432-3.008h10.88l-.064-5.952c0-1.066-.33-2.037-.992-2.912-.661-.874-1.781-1.312-3.36-1.312h-8c-1.578 0-2.698.438-3.36 1.312-.661.875-.992 1.846-.992 2.912v18.112z'
];

let shapes = paths.map(d => {
    let path = new Path2D(d);
    let speed = 2 + Math.random();
    speed = speed * ((Math.random() > .5) ? 1 : -1);
    return {path, speed};
});

let draw = (entities, entity, ctx) => {
    let fgColor = '#f2f7f6';
    ctx.fillStyle = fgColor;
    let offsetY = entities.game.canvas.oX - entities.game.canvas.gX;
    shapes.forEach(shape => {
        let localOffsetY = offsetY * shape.speed;
        ctx.translate(0, localOffsetY);
        ctx.fill(shape.path);
        ctx.translate(0, -localOffsetY);
    });
};

export default Object.freeze({
    draw
});
