import React, { Component } from "react";
import { Link } from "react-router-dom";
import { userContext } from "../Context/userContext";
import { useContext, useState, useEffect } from "react";
import "../css/LynxStyle.css";
import { Menu } from "react-feather";
//import { Collapse } from "bootstrap";
const Header = () => {
  // const [linkpath, setlinkpath] = useState("/");
  //const [linkname, setlinkname] = useState("Login");
  const {
    Loggedin,
    //setLoggedin,
    // Msisdn,
    //setMsisdn,
    DateOk,
    // setDateOk,
    //oContent,
    //setoContent,
    ShowMenu,
    setShowMenu,
  } = useContext(userContext);

  const getLink = () => {
    if (Loggedin) {
      return "/main";
    } else {
      return "/";
    }
  };

  const getLinkText = () => {
    if (Loggedin) {
      return "Main";
    } else {
      return "Login";
    }
  };
  function Logout() {
    if (Loggedin) {
      return (
        <li className="nav-item mx-0 mx-lg-1">
          <Link
            className="navbar-toggler text-uppercase font-weight-bold bg-primary text-white rounded"
            to="/Welcome"
          >
            Logout
          </Link>
        </li>
      );
    }
  }
  return (
    <div>
      <nav
        className="navbar navbar-expand-lg  text-uppercase navbar-static-top fixed-top Lynx_navbar"
        id="mainNav"
      >
        <div className="container">
          {DateOk === true && (
            <a className="text-white" onClick={() => setShowMenu(!ShowMenu)}>
              <Menu />
              {"  "}
            </a>
          )}
          <Link className="navbar-brand text-white" to="/Welcome">
            Lynx Lifestyle
          </Link>

          <Link
            to={Loggedin ? "/main" : "/"}
            className="navbar-toggler text-uppercase font-weight-bold bg-primary text-white rounded"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            {Loggedin ? "Main" : "Login"}

            <i className="fas fa-bars"></i>
          </Link>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item mx-0 mx-lg-1">
                <Link
                  className="nav-link py-3 px-0 px-lg-3 rounded text-white"
                  to={Loggedin ? "/main" : "/"}
                >
                  {Loggedin ? "Main" : "Login"}
                </Link>
              </li>
              {Logout()}
              <li className="nav-item mx-0 mx-lg-1">
                <Link
                  className="nav-link py-3 px-0 px-lg-3 rounded text-white"
                  to="/terms"
                >
                  Terms
                </Link>
              </li>
              <li className="nav-item mx-0 mx-lg-1 smsgpt">
                <a
                  href="https://smsgpt.co.za/"
                  className="nav-link py-3 px-0 px-lg-3 rounded smsgpt"
                >
                  SMSGPT
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
