import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { userContext } from "../Context/userContext";
import "../css/gridgallery.css";
import Loading from "./Loading";

const Audio = (props) => {
  const { DateOk } = useContext(userContext);
  const [loading, setLoading] = useState(false);
  const [idList, setIdList] = useState([]);
  const [loadingHeading, setloadingHeading] = useState("Loading...");

  useEffect(() => {
    setLoading(true);
    setloadingHeading("Loading...");
    if (DateOk) {
      axios
        .get(
          "https://lynxlife.club/api/lv1/content/list/" +
            props.contentType +
            "/0/0"
        )
        .then(function (res) {
          setIdList(res.items.id);
          setLoading(false);
        });
    }
  }, [props.contentType]);
  //if (DateOk){
  //axios.get("http://localhost:51184/lv1/content/" + Msisdn).then(function (res) {
  //console.log(JSON.stringify(res));
  //if (res.data.bLogin) {
  //setDateOk(res.data.bDateOk);
  //setLoggedin(res.data.bLogin);
  //setMsisdn(res.data.sMsisdn);
  // setoAud(res.data.oAudio);
  //setoWP(res.data.oWallpaper);
  // setoImg(res.data.oImg);
  //  setoZoom(res.data.oZoom);
  //}
  // navigate("../main"); //navigate anyway...checks done on main
  // });
  //}

  return (
    <div className="container">
      {" "}
      <div className="gallery-container">
        <h2 className="text-white">
          {loading ? <Loading heading={loadingHeading} /> : props.contentType}
          🔥
        </h2>
        <div className="gallery-grid">
          {idList.map((id) => (
            <audio key={id} controls>
              {" "}
              <source
                src={`https://lynxlife.club/api/lv1/content/${props.contentType}/${id}`}
                type="audio/mp3"
              />
              Your browser does Not support the audio element.
            </audio>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Audio;
