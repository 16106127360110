import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const MiniCardAboutGPT = () => {
  return (
    <div className="container-fluid">
      <div className="card-group col-sm">
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">How It works</p>
            <small className="card-text ">
              Type your question above and hit Submit! The answer you receive is
              from ChatGPT!
            </small>
          </div>
        </div>
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">AI POWERED</p>
            <small className="card-text ">
              CHAT GPT AI POWERED. OPENAI T&Cs and rules apply
            </small>
          </div>
        </div>
      </div>
      <div className="jumbotron lynx_opacity_text text-center">
        <h1 className="display-4">Example questions!</h1>
      </div>
      <div className="card-group col-sm">
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">What is bitcoin?</p>
            <small className="card-text smsgpt">
              Bitcoin is a digital currency that operates on a decentralized
              network. It allows for secure, peer-to-peer transactions without
              the need for intermediaries.
            </small>
          </div>
        </div>
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">What is the answer to the universe?</p>
            <small className="card-text smsgpt">
              In Douglas Adams' "The Hitchhiker's Guide to the Galaxy," the
              answer to the ultimate question of life, the universe, and
              everything is 42.
            </small>
          </div>
        </div>
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">
              What is a good investment strategy for beginners
            </p>
            <small className="card-text smsgpt">
              A good investment strategy for beginners is to start with a
              diversified portfolio of low-cost index funds or ETFs and
              consistently contribute to it over time.
            </small>
          </div>
        </div>
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">How do I get better at chess</p>
            <small className="card-text smsgpt">
              To get better at chess, practice regularly and study chess
              concepts such as tactics, strategy, and endgame principles.
              Analyze your games and learn from your mistakes.
            </small>
          </div>
        </div>
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">
              Please say " I love South Africa" in zulu
            </p>
            <small className="card-text smsgpt">
              "Ngilondoloze ngeNingizimu Afrika" is how to say "I love South
              Africa" in Zulu.
            </small>
          </div>
        </div>
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">Tell me 3 cool facts</p>
            <small className="card-text smsgpt">
              The Great Wall of China is not visible from space, a group of
              flamingos is called a flamboyance, and the first recorded marathon
              was run by a soldier.
            </small>
          </div>
        </div>
      </div>
      <div className="card-group col-sm">
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">How do you play paddle</p>
            <small className="card-text smsgpt">
              Paddle, also known as platform tennis, is played on a smaller
              court with solid paddles and a spongy ball. The game is similar to
              tennis, but with unique rules and strategies.
            </small>
          </div>
        </div>
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">most fuel efficient cars to own</p>
            <small className="card-text smsgpt">
              Some of the most fuel-efficient cars to own include Toyota Prius,
              Hyundai Ioniq, Honda Insight, Kia Niro, and Tesla Model 3.
            </small>
          </div>
        </div>
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">Tips for load shedding?</p>
            <small className="card-text smsgpt">
              Keep a battery-operated radio and flashlight handy, charge devices
              when the power is on, stock up on non-perishable food and water,
              consider alternative power sources, and unplug appliances to
              protect from surges.
            </small>
          </div>
        </div>
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">How can i loose weight</p>
            <small className="card-text smsgpt">
              Create a calorie deficit with a balanced diet and exercise. Reduce
              processed foods and added sugars, increase protein and fiber, and
              do cardio and strength training
            </small>
          </div>
        </div>
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">How do i make a braai?</p>
            <small className="card-text smsgpt">
              To make a braai, start a fire with wood or charcoal. When the
              flames have died down, add your meat, turning it occasionally.
              Serve with sides and enjoy!
            </small>
          </div>
        </div>
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">What can i do in South Africa</p>
            <small className="card-text smsgpt">
              In South Africa, you can enjoy wildlife safaris, explore beautiful
              beaches, visit historical sites, taste delicious food and wine,
              and experience diverse cultures.
            </small>
          </div>
        </div>
      </div>
      <div className="card-group col-sm">
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">Android or Apple? </p>
            <small className="card-text smsgpt">
              Choosing between Android or Apple is a matter of personal
              preference. Android offers more customization options, while Apple
              provides better privacy and security.{" "}
            </small>
          </div>
        </div>
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">How can i make extra money</p>
            <small className="card-text smsgpt">
              You can make extra money by freelancing, taking online surveys,
              selling items you no longer need, delivering food or groceries,
              tutoring, or driving for ride-sharing services.{" "}
            </small>
          </div>
        </div>
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">Natural cures for a cough</p>
            <small className="card-text smsgpt">
              Natural cures for a cough include staying hydrated, using a
              humidifier, drinking tea with honey and lemon, gargling salt
              water, and taking herbs like ginger, turmeric, and licorice root.{" "}
            </small>
          </div>
        </div>
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">How do I get better at soccer</p>
            <small className="card-text smsgpt">
              To become better at soccer, practice regularly, work on your
              technique, develop your physical fitness, study the game, and play
              with others who challenge you.{" "}
            </small>
          </div>
        </div>
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">
              Please say "i live in Pretoria" in xhosa
            </p>
            <small className="card-text smsgpt">
              "Ndiphola ePitoli" is "I live in Pretoria" in Xhosa.
            </small>
          </div>
        </div>
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">
              Please give me advise to put me in a good mood
            </p>
            <small className="card-text smsgpt">
              Listen to upbeat music, spend time outdoors, practice gratitude,
              do light exercise, and connect with loved ones.
            </small>
          </div>
        </div>
      </div>
      <div className="card-group col-sm">
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">
              {" "}
              Im nervous for a job interview, please help!
            </p>
            <small className="card-text smsgpt">
              Prepare well, research the company, practice interview questions,
              be confident, take deep breaths, visualize success, and stay
              positive.
            </small>
          </div>
        </div>
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">Top movies to watch</p>
            <small className="card-text smsgpt">
              Some top movies to watch include The Godfather, The Shawshank
              Redemption, The Dark Knight, Forrest Gump, and Pulp Fiction.
            </small>
          </div>
        </div>
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">Tell me a joke</p>
            <small className="card-text smsgpt">
              Why did the tomato turn red? Because it saw the salad dressing!
            </small>
          </div>
        </div>
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">how do you make Koeksisters</p>
            <small className="card-text smsgpt">
              To make Koeksisters, mix flour, sugar, yeast, and water, shape the
              dough, deep-fry until golden, and coat in a sweet syrup.{" "}
            </small>
          </div>
        </div>
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">How can i save petrol?</p>
            <small className="card-text smsgpt">
              To save petrol, drive at a steady speed, avoid rapid acceleration
              and braking, remove excess weight, and maintain proper tire
              pressure.{" "}
            </small>
          </div>
        </div>
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title"> What Animals are in south africa</p>
            <small className="card-text smsgpt">
              Some animals found in South Africa include lions, elephants,
              rhinos, leopards, zebras, giraffes, hippos, and crocodiles.{" "}
            </small>
          </div>
        </div>
      </div>
      <div className="card-group col-sm">
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">Make me a dating app bio?</p>
            <small className="card-text smsgpt">
              Exploring the world one adventure at a time. Seeking a partner in
              crime to share laughs, good food, and long walks. Lover of music
              and avid reader
            </small>
          </div>
        </div>
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">How to make the perfect coffee?</p>
            <small className="card-text smsgpt">
              Grind fresh beans. Use filtered water. Brew with precision. Enjoy
              the aroma and savor the taste. Experiment to find your perfect
              cup.
            </small>
          </div>
        </div>
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">Xbox Series X or PS5</p>
            <small className="card-text smsgpt">
              Both offer next-gen gaming. Xbox has better backward
              compatibility, while PS5 has faster load times and more exclusive
              titles. Choose based on your preferences.
            </small>
          </div>
        </div>
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">Write me a haiku</p>
            <small className="card-text smsgpt">
              Amidst falling leaves, Autumn whispers a goodbye, Nature's
              lullaby.
            </small>
          </div>
        </div>
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">Best exercise to burn calories</p>
            <small className="card-text smsgpt">
              High-intensity interval training (HIIT) burns more calories than
              traditional cardio. It involves short bursts of intense exercise
              followed by rest periods.
            </small>
          </div>
        </div>
        <div className="card  lynx_gptcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">How do i tell if my eggs are fresh?</p>
            <small className="card-text smsgpt">
              Place eggs in water. If they sink, they're fresh. If they float,
              they're old. The older the egg, the more it will float.
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniCardAboutGPT;
