import React from 'react';
import ReactDOM from 'react-dom/client';

//import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css'


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <>
 <App/>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

//<Router>
  //<Routes>
 // <Route path="/" element={<Login />}></Route> 
   //<Route path="/terms" element={<Terms />}></Route> 
  // </Routes>  
  // </Router>