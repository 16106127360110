import React, { useContext, useState } from "react";
import { userContext } from "../Context/userContext";
import Images from "./componentImages";
//import Zoom from "./componentZoom";
import Audio from "./componentAudio";
import SideBar from "./SideBar";
import Lifestyle from "./Lifestyle";
import BMICalories from "./BMICalories";
import BackHoleGame from "./BlackHoleGame";
import Games from "./Games"; //import SnakeGame from "./SnakeGame";
import FamTrack from "./FamTrack";
//import BlockGame from "./BlockGame";
//import SpaceShooter from "./BlockGame";
//import { Menu } from "react-feather";
//import Wallpapers from "./componentWallpapers";
//import axios from "axios";
import "../css/LynxStyle.css";
import ComponentGames from "./componentGames";
//import { getSpaceUntilMaxLength } from "@testing-library/user-event/dist/utils";

const Media = () => {
  const { contentType, ShowMenu } = useContext(userContext);

  //const getImg = () => {
  //  axios
  //   .get("http://localhost:51184/lv1/content/image/1")
  //   .then(function (res) {
  //    console.log(JSON.stringify(res));
  //    let // blob = new Blob[res.data](),
  //     blob = new Blob([res.data], { type: "img/jpg" }),
  //    downloadUrl = window.URL.createObjectURL(blob),
  // filename = "",
  // file = new File([res.data], filename),
  //   disposition = res.headers["content-disposition"];
  // console.log(downloadUrl);
  //  return downloadUrl;
  // });
  //};
  // /AllGames
  return (
    <div>
      {ShowMenu === true && <SideBar />}
      {(contentType === "image" && <Images contentType={contentType} />) ||
        (contentType === "zoom" && <Images contentType={contentType} />) ||
        (contentType === "wallpaper" && <Images contentType={contentType} />) ||
        (contentType === "audio" && <Audio contentType={contentType} />) ||
        (contentType === "life" && <Lifestyle />) ||
        (contentType === "BMI" && <BMICalories />) ||
        (contentType === "BHGame" && <BackHoleGame />) ||
        (contentType === "famtrack" && <FamTrack />) ||
        // (contentType === "SnakeGame" && <Games gameurl="snakegame/snake.html" />) ||
        (contentType === "AllGames" && <ComponentGames />)}
    </div>
  );
};
//{(contentType === "i" && <Images contentType={contentType} />) ||
// (contentType === "z" && <Images />) ||
// (contentType === "w" && <Images />) ||
// (contentType === "s" && <Audio />)}

//  {(contentType==="Audio" ? <Audio /> :  <Images Type={contentType} />)}
export default Media;
