import React, { useEffect } from "react";

import Loading from "./Loading";

import "../css/LynxStyle.css";

import dataSystem from "../BlackHole/systems/data.js";
import gameSystem from "../BlackHole/systems/game.js";
import entities from "../BlackHole/entities.json";

const BackHoleGame = () => {
  let current = dataSystem.load("current");

  useEffect(() => {
    gameSystem.setup(entities);

    return () => {
      gameSystem.gameEnd(); // Call the destroy method to end the game and clean up resources
      current = null;
      // Perform any necessary cleanup here
      // This function is called when the component is unmounted
    };
  }, []);

  return <div className="container"></div>;
};

export default BackHoleGame;
