import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

import Win from "./Components/Win";
import Login from './Components/componentLogin'
import Header from './Components/Header'
import Terms from './Components/Terms';
import Welcome from "./Components/Welcome";
import Content from "./Components/Content";
import Join from "./Components/Join";
import SubWin from "./Components/SubWin";
import LynxSub from "./Components/LynxSub";
import React, { useState } from "react";
import {userContext} from "./Context/userContext";
import CreateInvoice from "./Components/CreateInvoice";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import IQquiz from "./Components/IQquiz";

function App() {
  serviceWorkerRegistration.register();
const [Msisdn, setMsisdn] = useState("");
const [Loggedin, setLoggedin] = useState(false);
const [DateOk, setDateOk] = useState(false);
const [oAud, setoAud] = useState();
const [oImg, setoImg] = useState();
const [oWP, setoWP] = useState();
const [oZoom, setoZoom] = useState();
const [ShowMenu, setShowMenu]  = useState(true);
const [ShowMenuItem, setShowMenuItem]  = useState("");
const [contentType, setcontentType] = useState("life");

  return (
    
<Router>
<userContext.Provider value={{Loggedin, setLoggedin, Msisdn, setMsisdn, DateOk, setDateOk,oAud, setoAud,oWP, setoWP,oImg, setoImg,oZoom, setoZoom, ShowMenu, setShowMenu,contentType, setcontentType, ShowMenuItem, setShowMenuItem }}>
    <div >
      <Header/>      
  <Routes>
 
    <Route path="*"  element={<Login/>}></Route> 
       <Route path="/main" element={<Content/>}></Route>
      <Route path="/terms"  element={<Terms/>}></Route>
      <Route path="/Welcome"  element={<Welcome/>}></Route>
      <Route path="/create"  element={<CreateInvoice/>}></Route>
      <Route path="/Win"  element={<Win/>}></Route>
      <Route path="/Join"  element={<Join/>}></Route>
      <Route path="/Sub"  element={<SubWin/>}></Route>
      <Route path="/LynxSub"  element={<LynxSub/>}></Route>

      <Route path="/Quiz"  element={<IQquiz/>}></Route>


   </Routes>
  
  
    </div>
    </userContext.Provider>
    </Router>
    
  );
 

}

export default App;
