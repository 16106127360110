import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { userContext } from "../Context/userContext";

const CreateInvoice = () => {
  const { Msisdn } = useContext(userContext);
  return (
    <div className="container Lynx_docs">
      <div className="row">
        <div className="col">
          <h4>To</h4>
          <div className="form-horizontal">
            <div className="form-group">
              <label for="txtBank" className="col-sm-2 control-label">
                Name/Company
              </label>
              <div className="col-sm-10">
                <textarea
                  className="form-control"
                  runat="server"
                  placeholder="To:Name"
                ></textarea>
              </div>
            </div>
            <div className="form-group">
              <label for="txtBank" className="col-sm-2 control-label">
                Address Line 1
              </label>
              <div className="col-sm-10">
                <textarea
                  className="form-control"
                  runat="server"
                  placeholder="To:Address line 1"
                ></textarea>
              </div>
            </div>
            <div className="form-group">
              <label for="txtBank" className="col-sm-2 control-label">
                Address Line 2
              </label>
              <div className="col-sm-10">
                <textarea
                  className="form-control"
                  runat="server"
                  placeholder="To:Address Line 2"
                ></textarea>
              </div>
            </div>
            <div className="form-group">
              <label for="txtBank" className="col-sm-2 control-label">
                Address line 3{" "}
              </label>
              <div className="col-sm-10">
                <textarea
                  className="form-control"
                  runat="server"
                  placeholder="To:Address line 3"
                ></textarea>
              </div>
            </div>
            <div className="form-group">
              <label for="txtBank" className="col-sm-2 control-label">
                Tax number
              </label>
              <div className="col-sm-10">
                <textarea
                  className="form-control"
                  runat="server"
                  placeholder="To:Tax number"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <h4>From</h4>
          <div className="form-horizontal">
            <div className="form-group">
              <label for="txtBank" className="col-sm-2 control-label">
                Name/Company
              </label>
              <div className="col-sm-10">
                <textarea
                  className="form-control"
                  runat="server"
                  placeholder="From:Name"
                ></textarea>
              </div>
            </div>
            <div className="form-group">
              <label for="txtBank" className="col-sm-2 control-label">
                Address Line 1
              </label>
              <div className="col-sm-10">
                <textarea
                  className="form-control"
                  runat="server"
                  placeholder="From:Address line 1"
                ></textarea>
              </div>
            </div>
            <div className="form-group">
              <label for="txtBank" className="col-sm-2 control-label">
                Address Line 2
              </label>
              <div className="col-sm-10">
                <textarea
                  className="form-control"
                  runat="server"
                  placeholder="From:Address Line 2"
                ></textarea>
              </div>
            </div>
            <div className="form-group">
              <label for="txtBank" className="col-sm-2 control-label">
                Address line 3{" "}
              </label>
              <div className="col-sm-10">
                <textarea
                  className="form-control"
                  runat="server"
                  placeholder="From:Address line 3"
                ></textarea>
              </div>
            </div>
            <div className="form-group">
              <label for="txtBank" className="col-sm-2 control-label">
                Tax number
              </label>
              <div className="col-sm-10">
                <textarea
                  className="form-control"
                  runat="server"
                  placeholder="From:Tax number"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <h4>Invoice item</h4>
        <div className="form-horizontal">
          <div className="form-group">
            <label for="txtBank" className="col-sm-2 control-label">
              Code
            </label>
            <div className="col-sm-10">
              <textarea
                className="form-control"
                runat="server"
                placeholder="Invoice:code"
              ></textarea>
            </div>
          </div>
          <div className="form-group">
            <label for="txtBank" className="col-sm-2 control-label">
              Description
            </label>
            <div className="col-sm-10">
              <textarea
                className="form-control"
                runat="server"
                placeholder="Invoice:description"
              ></textarea>
            </div>
          </div>
          <div className="form-group">
            <label for="txtBank" className="col-sm-2 control-label">
              Number
            </label>
            <div className="col-sm-10">
              <textarea
                className="form-control"
                runat="server"
                placeholder="Invoice:Number"
              ></textarea>
            </div>
          </div>
          <div className="form-group">
            <label for="txtBank" className="col-sm-2 control-label">
              Cost
            </label>
            <div className="col-sm-10">
              <input
                className="text"
                runat="server"
                placeholder="Invoice:Cost"
              ></input>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateInvoice;
