import React, { useEffect, useState } from "react";

const ComponentGames = () => {
  //const GamesList = [
  //   {
  //    "name": "Snake",
  //    "src": "/snakegame/snake.html",
  //    "img": ""
  //  },
  //   {
  //    "name": "Ninja",
  //   "src": "/NinjaGame/index.html",
  //   "img": ""
  //  },
  //  {
  //    "name": "Connect",
  //    "src": "/ConnectFour/index.html",
  //    "img": ""
  //  },
  //  {
  //    "name": "Blocks",
  //    "src": "/BlockGame/index.html",
  //    "img": ""
  //   },
  //   {
  //    "name": "Scary",
  //    "src": "/ScaryGame/index.html",
  //    "img": ""
  //  }
  // ]
  const [games, setGames] = useState([
    {
      name: "Snake",
      src: "/snakegame/snake.html",
      img: "/snakegame/snake.jpg",
    },
    {
      name: "Ninja",
      src: "/NinjaGame/index.html",
      img: "/NinjaGame/ninja.png",
    },
    {
      name: "Connect",
      src: "/ConnectFour/index.html",
      img: "/ConnectFour/connection.png",
    },
    {
      name: "Blocks",
      src: "/BlockGame/index.html",
      img: "/BlockGame/block.png",
    },
    {
      name: "DotShooter",
      src: "/DotShooter/index.html",
      img: "/DotShooter/DotShooter.png",
    },
    {
      name: "HyperSpace",
      src: "/HyperSpace/index.html",
      img: "/HyperSpace/HyperSpace.png",
    },
    {
      name: "HeroGame",
      src: "/HeroGame/index.html",
      img: "/HeroGame/hero.png",
    },
    {
      name: "SpaceCapture",
      src: "/SpaceCapture/index.html",
      img: "/SpaceCapture/SpaceCapture.png",
    },
    {
      name: "CircuitGame",
      src: "/CircuitGame/index.html",
      img: "/CircuitGame/Circuit.png",
    },
  ]);

  // useEffect(() => {
  //   fetchGamesList();
  // }, []);

  // const fetchGamesList = async () => {
  // try {
  //const response = await fetch("../GamesList.json");
  //const data = await response.json();
  //   setGames(GamesList);
  // } catch (error) {
  //  console.error("Error fetching games list:", error);
  // }
  //};

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <div className="container">
      <br />
      <h3 className="text-white">Awesome Games🕹️</h3>
      <div className="gallery-grid">
        {games.map((game, index) => (
          <button
            className="btn btn-dark"
            key={index}
            onClick={() => openInNewTab(game.src)}
          >
            {game.name}
            <img src={game.img} width="120" height="120"></img>
          </button>
        ))}
      </div>
    </div>
  );
};

export default ComponentGames;
