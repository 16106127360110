import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const LynxSub = () => {
  const [msisdn, setMsisdn] = useState(""); // State for MSISDN input
  const [network, setNetwork] = useState("VODACOM");
  const [svc, setSvc] = useState("LynxL");

  const [loadingsub, setLoadingsub] = useState(false);
  const [errorsub, setErrorsub] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleJoin = async () => {
    if (!msisdn) {
      setErrorsub("MSISDN cannot be empty");
      return;
    }

    setLoadingsub(true);
    setErrorsub(null);
    setSuccess(false);

    try {
      const response = await axios.post(`https://lynxlife.club/api/lv1/join/${network}/${svc}/${msisdn}`);
      console.log("Join response:", response.data);
      setSuccess(true);
      // Handle success or redirect to another page
    } catch (error) {
      console.error("Join error:", error);
      setErrorsub("An error occurred. Please try again.");
    } finally {
      setLoadingsub(false);
    }
  };

  return (
    <div className="container-fluid viewport">
      <section className="text-center text-lg-start">
        <div className="container">
          <div className="row g-0 align-items-center">
            <div className="col-lg-6 mb-5 mb-lg-0">
              <div className="lynx_darkcards_noborder cascading-right img_bootstrap">
                <div className="p-5 text-center">
                  <h2 className="lynx_opacity_text">Lynx LifeStyle Club and Airtime AWARDS!</h2>
                  <div className="form-outline">
                    <div className="lynx_opacity_text text-center">
                      <h1>Awesome Airtime Awards!</h1>
                      <h3>Want a chance to win airtime and join the club?</h3>
                      <br />
                      <p>
                        Enter your number below for <strong>UNLIMITED</strong> content and a chance to <strong>WIN AIRTIME PRIZES</strong>
                        <br />
                      </p>
                      <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Cell</span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Your Cell Number"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          id="msisdn"
                          value={msisdn}
                          onChange={(e) => setMsisdn(e.target.value)}
                        />
                      </div>
                      {errorsub && <p style={{ color: 'red' }}>{errorsub}</p>}
                      {success && <p style={{ color: 'green' }}>Successfully joined!</p>}
                      <br />
                      <div className="d-grid gap-2">
                        <button
                          onClick={handleJoin}
                          className="btn btn-success"
                          disabled={loadingsub}
                        >
                          {loadingsub ? "Joining..." : "Join Now"}
                        </button>
                      </div>
                      <br />
                      <br />
                      <br />
                      Thousands of Rands awarded already. Don’t miss out on your chance to win!
                      <br />
                      <small>
                        R3.50 per day, T&Cs apply To unsubscribe dial *135*997#
                        <br />
                        <Link className="lynx_opacity_text btn nav-link" to="/Terms?md=35">Terms</Link>
                        <br />
                        Info@lynxlife.club
                        <br />
                        0109003333 (std rates apply)
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-5 mb-lg-0 container-fluid lynx_m_img">
              <img src="fun.jpg" className="rounded-4 lynx_m_img shadow-4 img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LynxSub;
