import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import { userContext } from "../Context/userContext";
import MiniCardAbout from "./MiniCardAbout";

const Login = () => {
  let navigate = useNavigate();
  const {
    setLoggedin,
    Msisdn,
    setMsisdn,
    setDateOk,
  } = useContext(userContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(""); // State for error message

  const HandleLogin = (e) => {
    if (Msisdn.trim() === "") { // Check if Msisdn is blank
      setError("Please fill in your cellphone number.");
      return;
    }
    setError(""); // Clear any previous errors
    setLoading(true);

    axios
      .get("https://lynxlife.club/api/lv1/login/" + Msisdn)
      .then(function (res) {
        console.log(JSON.stringify(res));
        if (res.data.bLogin) {
          setDateOk(res.data.bDateOk);
          setLoggedin(res.data.bLogin);
          setMsisdn(res.data.sMsisdn);
        }
        setLoading(false);
        navigate("../main"); //navigate anyway...checks done on main
      })
      .catch((error) => {
        setLoading(false);
        setError("Login failed. Please try again."); // Set error message if request fails
      });
  };

  return (
    <div className="container-fluid viewport">
      <section className="text-center text-lg-start">
        <div className="container">
          <div className="row g-0 align-items-center">
            <div className="col-lg-6 mb-5 mb-lg-0">
              <div className="lynx_darkcards_noborder cascading-right img_bootstrap">
                <div className="p-5 text-center">
                  <h2 className="lynx_opacity_text">Lynx LifeStyle Club</h2>
                  <h5 className="lynx_opacity_text">Sign into your account</h5>
                  <div className="form-outline">
                    {loading === false ? (
                      <>
                        <input
                          type="text"
                          className="form-control"
                          id="txtMsisdn"
                          onChange={(e) => setMsisdn(e.target.value)}
                        />
                        {error && (
                          <div className="text-danger mt-2">{error}</div>
                        )}
                      </>
                    ) : (
                      <div className="spinner-border text-dark" role="status">
                        <span className="sr-only"></span>
                      </div>
                    )}
                    <label
                      className="form-label lynx_opacity_text"
                      htmlFor="form2Example17"
                    >
                      Cellphone number
                    </label>
                  </div>
                  <div className="form-outline"></div>
                  <div className="">
                    {loading ? (
                      <div className="spinner-border text-dark" role="status">
                        <span className="sr-only"></span>
                      </div>
                    ) : (
                      <button
                        className="btn btn-dark btn-lg btn-block"
                        type="button"
                        onClick={HandleLogin}
                      >
                        Login
                      </button>
                    )}
                  </div>
                  <p className="lynx_opacity_text">
                    Don't have an account? SMS lynx to 33270 (std sms rates, R2
                    per day, T&Cs apply)
                  </p>
                  <Link className="lynx_opacity_text btn nav-link" to="/Terms">
                    Terms
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-6 mb-5 mb-lg-0 container-fluid lynx_m_img">
              <img
                src="lynx2.jpg"
                className="rounded-4 lynx_m_img shadow-4"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <MiniCardAbout />
    </div>
  );
};

export default Login;
