import React, { useContext, useState } from "react";

import Loading from "./Loading";

import "../css/LynxStyle.css";
//import MiniCardAboutGPT from "./MiniCardAboutGPT";
//import { getSpaceUntilMaxLength } from "@testing-library/user-event/dist/utils";

const BMICalories = () => {
  const [loading, setLoading] = useState(false);
  const [loadingHeading, setloadingHeading] = useState("Calculating...");
  const [Height, setHeight] = useState(0);
  const [Weight, setWeight] = useState(0);
  const [Age, setAge] = useState(0);
  const [Answer, setAnswer] = useState("");
  const [gender, setGender] = useState("");

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const CalculateBMICalories = () => {
    setLoading(true);
    setHeight(Height / 100);
    let BMI = Height * Height;
    BMI = Weight / BMI;
    let BMR = 10 * Weight + 6.25 * Height - 5 * Age;

    if (gender === "male") {
      BMR = BMR + 5;
    } else if (gender === "female") {
      BMR = BMR - 161;
    }

    setAnswer(
      `Your BMI is: ${BMI.toFixed(
        2
      )} and the amount of calories you should consume each day is: ${BMR.toFixed(
        2
      )}`
    );

    setLoading(false);
  };

  return (
    <div className="container">
      <br />
      <br />
      <h3 className="text-white">BMI and Calorie Calculator</h3>
      <div className="container mt-5">
        <form>
          <div className="form-group">
            <label htmlFor="height" className="text-white">
              Height (cm):
            </label>
            <input
              type="number"
              onChange={(e) => setHeight(e.target.value)}
              className="form-control"
              id="height"
              placeholder="Enter height"
            />
          </div>
          <div className="form-group">
            <label htmlFor="weight" className="text-white">
              Weight (kg):
            </label>
            <input
              type="number"
              onChange={(e) => setWeight(e.target.value)}
              className="form-control"
              id="weight"
              placeholder="Enter weight"
            />
          </div>
          <div className="form-group">
            <label htmlFor="age" className="text-white">
              Age:
            </label>
            <input
              type="number"
              onChange={(e) => setAge(e.target.value)}
              className="form-control"
              id="age"
              placeholder="Enter age"
            />
          </div>
          <div className="form-group">
            <label htmlFor="gender" className="text-white">
              Gender:
            </label>
            <br />
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="gender"
                id="male"
                value="male"
                checked={gender === "male"}
                onChange={handleGenderChange}
              />
              <label className="form-check-label text-white" htmlFor="male">
                Male
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="gender"
                id="female"
                value="female"
                checked={gender === "female"}
                onChange={handleGenderChange}
              />
              <label className="form-check-label text-white" htmlFor="female">
                Female
              </label>
            </div>
          </div>
          <div className="text-center">
            <button
              type="button"
              onClick={CalculateBMICalories}
              className="btn btn-dark"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      <div className="smsgpt">
        {loading ? <Loading heading={loadingHeading} /> : Answer}
      </div>
      <br></br>
      <br></br>
      <h4 className="text-white">
        Below is the different ranges of BMI categoies. If you would like to
        loose or gain weight, chat to our friendly AI Powered Lynx Assistant!
      </h4>
      <table className="table table-bordered mt-3 text-white">
        <thead>
          <tr>
            <th>BMI</th>
            <th>Category</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>&lt; 18.5</td>
            <td>Thin</td>
          </tr>
          <tr>
            <td>18.5 - 25</td>
            <td>Normal</td>
          </tr>
          <tr>
            <td>25 - 30</td>
            <td>Overweight</td>
          </tr>
          <tr>
            <td>&gt; 30</td>
            <td>Obese</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BMICalories;
