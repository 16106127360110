import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userContext } from "../Context/userContext";

const SideBar = () => {
  const { contentType, setcontentType, ShowMenu, setShowMenu } =
    useContext(userContext);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  return (
    <div className="sidebar">
      <div
        className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark sidebar"
        style={{ width: 280 }}
      >
        <p className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
          <svg className="bi me-2" width="40" height="32">
            <use href="#bootstrap"></use>
          </svg>
          <span className="fs-4">Menu</span>
        </p>
        <hr />

        <ul className="nav nav-pills flex-column mb-auto">
          <span className="fs-4 smsgpt">Lifestyle💪</span>
          <li className="nav-item">
            <button
              className="btn nav-link text-white"
              onClick={() => setcontentType("life")}
            >
              Lifestyle Assistant
            </button>
          </li>
          <li>
            <button
              className="btn nav-link text-white"
              onClick={() => {
                setcontentType("BMI");
                setShowMenu(!ShowMenu);
              }}
            >
              BMI & Calories
            </button>
          </li>
          <li className="nav-item">
            <button
              className="btn nav-link text-white"
              onClick={() => {
                setcontentType("famtrack");
                setShowMenu(!ShowMenu);
              }}
            >
              Family Tracker
            </button>
          </li>
          <span className="fs-4 smsgpt">Content🎁</span>
          <li className="nav-item">
            <button
              className="btn nav-link text-white"
              onClick={() => {
                setcontentType("image");
                setShowMenu(!ShowMenu);
              }}
            >
              Images
            </button>
          </li>
          <li>
            <button
              className="btn nav-link text-white"
              onClick={() => {
                setcontentType("audio");
                setShowMenu(!ShowMenu);
              }}
            >
              Music
            </button>
          </li>
          <li>
            <button
              className="btn nav-link text-white"
              onClick={() => {
                setcontentType("wallpaper");
                setShowMenu(!ShowMenu);
              }}
            >
              Wallpapers
            </button>
          </li>
          <li>
            <button
              className="btn nav-link text-white"
              onClick={() => {
                setcontentType("zoom");
                setShowMenu(!ShowMenu);
              }}
            >
              Zoom Backgrounds
            </button>
          </li>
          <li>
            <span className="fs-4 smsgpt">Games🕹️</span>
          </li>
          <li>
            <button
              className="btn nav-link text-white"
              onClick={() => {
                setcontentType("BHGame");
                setShowMenu(!ShowMenu);
              }}
            >
              Black Hole Game
            </button>
          </li>
          <li>
            <button
              className="btn nav-link text-white"
              onClick={() => {
                //openInNewTab("/blockgame/index.html");
                setcontentType("AllGames");
                setShowMenu(!ShowMenu);
              }}
            >
              All Games!
            </button>
          </li>
        </ul>

        <hr />
      </div>
    </div>
  );
};

export default SideBar;
