import React, { useContext } from "react";
import { userContext } from "../Context/userContext";
import Media from "./Media";
import Welcome from "./Welcome";
import BillingErr from "./billingErr";

const Content = () => {
  const { Loggedin, DateOk } = useContext(userContext);
  let oDisplayComp;

  if (Loggedin) {
    if (DateOk) {
      oDisplayComp = <Media />;
    } else {
      oDisplayComp = <BillingErr />;
    }
  } else {
    oDisplayComp = <Welcome />;
  }
  return oDisplayComp;
};

export default Content;
