import React, { Component } from "react";
import { useSearchParams } from "react-router-dom";
const Terms = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getKW = (md) => {
    if (md === null) {
      md = "";
    }
    switch (md) {
      case "05":
        return "keyword|R0.50 per day";
      case "1":
        return "keyword|R1 per day";
      case "15":
        return "keyword|R1.50 per day";
      case "2":
        return "lynx|R2 per day";
      case "25":
        return "airtime|R2.50 per day";
      case "3":
        return "lynxwin|R3 per day";
      case "35":
        return "lynxair|R3.50 per day";
      case "4":
        return "keyword|R4 per day";
      case "45":
        return "keyword|R4.50 per day";
      case "5":
        return "keyword|R5 per day";
      case "55":
        return "keyword|R5.50 per day";
      case "6":
        return "keyword|R6 per day";
      case "65":
        return "keyword|R6.50 per day";
      case "7":
        return "keyword|R7 per day";
      case "75":
        return "keyword|R7.50 per day";
      case "":
        return "winr|R2 per day";
    }
  };
  return (
    <div className="container card card-info Lynx_terms">
      <p>
        Please note that by using this web site, you, the user, agree to observe
        and be legally bound by all Terms and Conditions of Use as set out
        below, including any other policies which may appear elsewhere on this
        web site. You also agree to observe and abide by all applicable laws and
        regulations.
      </p>

      <strong>1. General</strong>

      <p>
        <strong>1.1</strong> The site Is open to permanent residents And
        citizens of South Africa over the age of 18 (eighteen) years,
        (""Participant/s""), except any minor And any employee, director,
        member, partner, agent Or consultant Or any person directly Or
        indirectly who controls Or Is controlled by the Promoter And/Or
        immediate family members Of any employee, director, member, partner,
        agent Or consultant Of Or person indirectly Or directly In control Or
        controlled by the Promoter
      </p>
      <p>
        <strong>1.2</strong> In order to access the site, the handset must be
        subscribed and link must be active. Additionally, the service can only
        be accessed with permission of the subscriber
      </p>
      <p>
        <strong>1.3</strong> By subscribing to this service, the subscriber
        agrees to receive future communications, marketing and/or advertising
        from third parties to the number that is subscribed to the service{" "}
      </p>
      <p>
        <strong>1.4</strong> All existing and new subscribers are automatically
        entered into a competition to win airtime{" "}
      </p>
      <p>
        <strong>1.5</strong>Use of this web site is undertaken solely at the
        risk of the user.{" "}
      </p>
      <p>
        <strong>1.6</strong> No person, business or web site may frame this site
        or any of the pages on this site in any way whatsoever.{" "}
      </p>
      <p>
        <strong>1.7</strong> No person, business or web site may use any
        technology to search and gain any information from this site without the
        prior written permission of CellBright. Such permission could be
        obtained from the person referred to in clause 5 hereof.
      </p>
      <p>
        <strong>1.8</strong> All Subscribers automatically join the Lynx
        Lifestyle Club
      </p>
      <p>
        <strong>1.9</strong>
        Lynx LifeStyle, its services, content and airtime competition will not
        be used: (i) to intentionally engage in illegal conduct, (ii) to
        knowingly create, store or disseminate any illegal content, (iii) to
        knowingly infringe copyright, (iv) to knowingly infringe any
        intellectual property rights, or (v) to send spam or promote the sending
        of spam.
      </p>
      <p>
        <strong>1.10</strong>Lynx Lifestyle airtime competition is available to
        all handsets. In order to access the content offered by the club, Lynx
        Lifestyle content is available only to cellphones with a Mobile Browser
      </p>
      <strong>2. Competition</strong>
      <p>
        <strong>2.1</strong> All existing subscribers are automatically entered
        into a competition to win airtime
      </p>
      <p>
        <strong>2.2</strong> Winners will receive an SMS as well as an automated
        SMS from the network
      </p>
      <p>
        <strong>2.3</strong> Prizes are awarded daily and/or monthly
      </p>
      <p>
        <strong>2.4</strong> Prizes are in the form of airtime and are
        non-exchangeable and non-transferable
      </p>
      <strong>3. Subscription</strong>
      <p>
        <strong>3.1</strong> Lynx Lifestyle content costs{" "}
        {getKW(searchParams.get("md")).split("|")[1]} including VAT to access
        the site. Network and Admin fees may apply
      </p>

      <p>
        <strong>3.2</strong> In order to access the site, the handset must be
        subscribed and link must be active.
      </p>
      <p>
        <strong>3.3</strong> To subscribe SMS{" "}
        {getKW(searchParams.get("md")).split("|")[0]} to 33270(std sms
        rates/sms)({getKW(searchParams.get("md")).split("|")[1]}
        )(or the subscription charge agreed when subscribing).Network and Admin
        fees may apply
      </p>
      <p>
        <strong>3.4</strong> Subscribers that don't complete the subscription
        process or who unsubscribe, may receive the opportunity to resubscribe
        via a ussd link sent once every 30 days for 2 months.
      </p>
      <p>
        <strong>3.5</strong> Each existing and new subscriber is automatically
        entered into a competition to win airtime.
      </p>
      <p>
        <strong>3.6</strong> To unsubscribe SMS Stop{" "}
        {getKW(searchParams.get("md")).split("|")[0]} to 33270(std sms
        rates/sms)
      </p>
      <p>
        <strong>3.7</strong> The Subscription Service is provided by LogicSMS.
        LogicSMS is a member of WASPA and is bound by the WASPA Code of Conduct.
        Customers have the right to approach WASPA to lodge a complaint in
        accordance with the WASPA complaints procedure. LogicSMS may be required
        to share information relating to a service or a customer with WASPA for
        the purpose of resolving a complaint. WASPA web site: www.waspa.org.za”.
      </p>
      <p>
        <strong>3.8</strong> LogicSMS has the right to suspend or terminate the
        services of any customer who does not comply with these terms and
        conditions or any other related contractual obligations, and has the
        right to take down any content (hosted as part of the service) that it
        considers illegal or for which it has received a take-down notice{" "}
      </p>
      <p>
        <strong>3.9</strong> Any erroneous billing will be refunded to the
        handset that the airtime was deducted from. The owner of the handset
        should use the contact details below to report any unexpected or
        erroneous billing{" "}
      </p>

      <strong>4. Lynx Lifestyle Club</strong>
      <p>
        <strong>4.1</strong> All subscribers agree to receive SMS marketing
        messages from Lynx Lifestyle Club
      </p>
      <p>
        <strong>4.2</strong> To unsubscribe from receiving Marketing Messages
        from Lynx Lifestyle Club SMS stop to 49993(std sms rates/sms)
      </p>
      <strong>5. Lynx Cash</strong>
      <p>
        <strong>5.1</strong> All subscribers subscribed to LynxCash at R2 a day
        are automatically entered into a free draw to win a cash prize
      </p>
      <p>
        <strong>5.2</strong> Draws are done by selecting a winner randomly.
        Winners must have an active subscription. Winners will receive an SMS
        and Phonecall. Winners have 48 hours to claim their prize
      </p>
      <p>
        <strong>5.3</strong> Competition is offered to MTN users only. Users
        must be South African Citizens and 18 years or older. Users must have an
        official South African ID
      </p>
      <p>
        <strong>5.4</strong> To join SMS LynxCash to 33270 (Std SMS rates) or
        dial *130*686*10252# Subscription charged at R2/day
      </p>
      <p>
        <strong>5.5</strong> Random Draws are done on a weekly basis. There can
        be 1-3 winners per week. Prizes awarded to the winner via EFT(electronic
        funds transfer) and can take 24-48 hours to reflect
      </p>
      <p>
        <strong>5.6</strong> Lynx LifeStyle reserves the right to not award the
        cash prize if the Terms and Conditions are not met.
      </p>
      <p>
        <strong>5.7</strong> The competition will run for a limited time: 20
        October 2023 - 10 November 2023. Lynx LifeStyle reserves the right to
        extend this end date
      </p>
      <p>
        <strong>5.8</strong> All Active subscribers of LynxCash will
        automatically be entered to the WinAirime awards and have access to the
        LynxLife Club and SMSGPT services
      </p>
      <strong>Contact</strong>
      <p>
        <strong></strong> For further details email Info@lynxlife.club
      </p>
      <p>
        <strong></strong> Or call 0109003333 (std rates apply)
      </p>
      <p>
        <strong></strong> 13 Park Crescent, Glenhazel, JHB SA
      </p>
    </div>
  );
};
export default Terms;
