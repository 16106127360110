import React from "react";
//import { useNavigate } from "react-router-dom";

const MiniCardAbout = () => {
  return (
    <div className="container-fluid">
      <div className="jumbotron smsgpt text-center">
        <h1 className="smsgpt">
          <b>SMSGPT!</b> Check it out, Click{" "}
          <a href="https://smsgpt.co.za/">Here</a>{" "}
        </h1>
      </div>
      <div className="card-group col-sm">
        <div className="card  lynx_darkcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">LifeStyle Club</p>
            <small className="card-text text-muted">
              Content. Articles. Apps.
            </small>
            <small className="card-text text-muted">
              Draws to win aritime and other prizes
            </small>
          </div>
        </div>
        <div className="card  lynx_darkcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">Discounts</p>
            <small className="card-text text-muted">
              Discounts from our partners
            </small>
          </div>
        </div>
        <div className="card  lynx_darkcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">Win Prizes</p>
            <small className="card-text text-muted">
              Draws to win airtime and other prizes!
            </small>
          </div>
        </div>
      </div>
      <div className="jumbotron lynx_opacity_text text-center">
        <h1 className="display-4">Coming soon!</h1>
      </div>
      <div className="card-group col-sm">
        <div className="card  lynx_darkcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">100% Safe Browsing</p>
            <small className="card-text text-muted">
              Child Safe internet browser
            </small>
          </div>
        </div>
        <div className="card  lynx_darkcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">Secure Family Tracking</p>
            <small className="card-text text-muted">
              Keep your loved ones safe! View and Share Family Members location
              -- Press A panic to Alert your family
            </small>
          </div>
        </div>
        <div className="card  lynx_darkcards lynx_opacity_text">
          <div className="card-body">
            <p className="card-title">Business Documents</p>
            <small className="card-text text-muted">
              Invoicing. Travel Log. Fuel Efficiency
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniCardAbout;
